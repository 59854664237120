// import { CSS_HELPERS } from '@theme/constants';
// import { Badge, Divider, Tag, Tooltip } from 'antd';
import Link from 'next/link';
// import Rect from 'react'
// import JSONTree from 'react-json-tree';
// import styled from 'styled-components';

const BusquedaItem = ({
    slug,
    titulo,
    entrada,
}) => {

    const preSlug = entrada && entrada.slug && entrada.slug;
    //const url = preSlug ? `/${preSlug}/${slug}` : `/${slug}`
    const url = preSlug && preSlug !== '/' ? `/${preSlug}/${slug}` : `${slug}`;


    return (
        <Link href={url} >
            {titulo}
        </Link>
    )
}

export default BusquedaItem;