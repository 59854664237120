const Bolder = ({ titulo, bold, boldColor, color }) => {

    let split = titulo && titulo.split("*");
    return (
        <>
            {split && split.length && split.map((item, index) => {
                const textStyle = ((index % 2) && (bold != false)) ? 'strong900' : 'strong400'
                const textColor = ((index % 2) && (bold != false))
                    ? { color: boldColor, fontSize: 'inherit' }
                    : { fontSize: 'inherit' };

                return (<span key={index} className={`${textStyle} ${color} lineHeightStandarParragraph`} style={textColor}>{item}</span>)
            })}
        </>
    )


}

export default Bolder;