import { THEME_COLORS } from '@theme/constants';
import { Row, Col } from 'antd';
import React from 'react'
import styled from 'styled-components';
import IconSwitcher from '@components/common/IconSwitcher'
import BoxUi from '@components/common/BoxUi';
const BannerProvida = () => {
    return (
        <View>
            <BoxUi transparent>
                <Row gutter={[30,30]} justify="center" align="middle">
                    <Col>
                        <p>Con la experiencia internacional de </p>
                    </Col>
                    <Col>
                        <IconSwitcher name="provida_logo"  />
                    </Col>
                </Row>
            </BoxUi>
        </View>
    )
}

export default BannerProvida;

const View = styled.div`
    p{
        font-weight: 600;
        margin-bottom:0;
    }
    svg{
        height: 4.2em;
    }

    
`