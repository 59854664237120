import { CSS_HELPERS, MEDIA_SCREENS, THEME_COLORS } from '@theme/constants';
import { Row, Col } from 'antd';
import React from 'react'
import styled from 'styled-components';
import IconSwitcher from '@components/common/IconSwitcher'
import BoxUi from '@components/common/BoxUi';
import Link from 'next/link';
import { HiCursorClick } from 'react-icons/hi';


const BannerInvierteAqui = () => {

  return (
    <Link href="/cliente">
      <View>
        <BoxUi transparent>
          <Row gutter={[12, 24]}>
            <Col xs={24} md={18} xl={18} className="slogan">
              <p className="bold  light_primary_contrastText">Invierte por tu futuro, hazte cliente</p>
              <HiCursorClick
                size={60}
                color={THEME_COLORS.light.primary.contrastText}
              />
            </Col>
            <Col xs={24} md={6} xl={6} className="logo_container">
              <IconSwitcher name="contrast_logo" />
            </Col>
          </Row>
        </BoxUi>
      </View>
    </Link>

  )
}

export default BannerInvierteAqui;

const View = styled.div`
    ${CSS_HELPERS.BORDER_LINE_TOP}
    background-color:${THEME_COLORS.light.primary.main};
    height:18em;
    display:flex;
    flex-direction:column;
    justify-content:center;

    .slogan{
        display:flex; 
        align-items:center;
        cursor:pointer;
        p{
          font-size:1.8em;
        }
        svg {
          fill:#223A8F;
          height:100%;
        }
    }
    .logo_container{
      svg{
        font-size:2em;
        height:2em;
      }
      display:flex;
        @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.XS}px) { 
          justify-content:center;
        }
        @media screen and  (min-width: ${MEDIA_SCREENS.XS}px) and (max-width: ${MEDIA_SCREENS.SM}px) { 
          justify-content:center;
        }
        @media screen and  (min-width: ${MEDIA_SCREENS.SM}px) and (max-width: ${MEDIA_SCREENS.MD}px) { 
          justify-content:flex-end;
        }
        @media screen and  (min-width: ${MEDIA_SCREENS.MD}px) and (max-width: ${MEDIA_SCREENS.LG}px) { 
          justify-content:flex-end;
        }
    }


  
    
   
  
  
   
`