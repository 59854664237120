"use client";

import React, { useEffect, useState } from 'react'
import BoxUi from '@components/common/BoxUi';
import { useDispatch, useSelector } from '@redux/store';
import { THEME_COLORS, VALIDATE_MESSAGES } from '@theme/constants';
import { Button, Card, Col, Form, Input, Modal, Row } from 'antd'
import { FaUserPlus, FaUserShield } from 'react-icons/fa';
import CuadroSwitcher from '@components/common/CuadroSwitcher';
import { createUsuario, } from '@redux/slices/usuario';
import { loginUsuario } from '@redux/slices/auth/sesion';
import { useRouter } from 'next/navigation';

const LoginForm = () => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const state = useSelector(state => state.usuario);

    const router = useRouter();

    //
    const rules = {
        nombres: [
            { required: true, message: 'Su nombre es obligatorio' },
        ],
        correo: [
            { required: true },
            { type: 'email', message: 'Debe ingresar un correo electrónico válido' }
        ],
        contrasena: [
            { required: true },
            { type: 'string', message: 'Debe ingresaar una contrasña válida' },
        ]
    };

    //
    const onFinish = (values) => {
        dispatch(loginUsuario(values, router.push('/manager')));
    };


    return (
        <BoxUi background justifyAll >
            <Form form={form}
                layout="vertical"
                validateMessages={VALIDATE_MESSAGES}
                onFinish={onFinish}
                onFinishFailed={() => { }}
            >
                <Card title="Login" bodyStyle={{ padding: 0 }} >
                    <Row justify="center">
                        <Col xs={24} md={12} >
                            <CuadroSwitcher name="rockets_illustration" />
                        </Col>
                        <Col xs={24} md={12}>
                            <Row gutter={[15, 15]} style={{ padding: '1em' }}>
                                <Col xs={24}>

                                    <Form.Item label="Correo" name="correo" rules={rules.correo}>
                                        <Input className='ant-custom-input' placeholder="mail@mail.com" type="email" />
                                    </Form.Item>
                                    <Form.Item label="Contrasena" name="contrasena" rules={rules.contrasena}>
                                        <Input.Password placeholder="" type="password" className='ant-custom-input' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Button loading={state.loading} type="primary" size="large" htmlType="submit" style={{ width: "42em", maxWidth: "100%" }}>
                                        Iniciar Sesión
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </BoxUi>
    );
}

export default LoginForm;