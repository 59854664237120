import { useState } from 'react';
import BoxUi from '@components/common/BoxUi'
import IconSwitcher from '@components/common/IconSwitcher'
import { CSS_HELPERS, MEDIA_SCREENS, THEME_COLORS } from '@theme/constants'
import { Row, Col, Modal, List, Avatar, Divider, Button } from 'antd'
import Link from 'next/link'
import { IoPhoneLandscape, IoPhonePortraitOutline } from 'react-icons/io5'
import JSONTree from 'react-json-tree'
import styled from 'styled-components'
import { useSelector } from 'react-redux';
import { SimuladorCard } from './SimuladorCard';
import { BsCalculator } from 'react-icons/bs';
import { BiPhoneCall } from 'react-icons/bi';

const Footer = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { fondoOptions } = useSelector(state => state.simuladorCortoPlazo);
  const { simulador } = useSelector(state => state.simuladorHorizonte);

  const socialItems = [
    {
      "titulo": "Facebook",
      "icon": "facebook",
      "url": "https://www.facebook.com/FondosGenesis"
    },
    {
      "titulo": "Instagram",
      "icon": "instagram",
      "url": "https://www.instagram.com/fondosgenesis/"
    },
    {
      "titulo": "Linkedin",
      "icon": "linkedin",
      "url": "https://www.linkedin.com/company/fondos-genesis/"
    },
  ];

  return (
    <>
      <View>
        <BoxUi noppadingX transparent>
          <Row gutter={[12, 12]}>
            <Col xs={0} md={0} lg={9} xxl={9} justify="center" align="middle">
              <Row align='middle' style={{ height: '100%' }}>
                <IoPhonePortraitOutline size={27} />
                <span>Línea de Atención al Cliente <span className="strong900">0986044405</span></span>
              </Row>
            </Col>
            <Col xs={0} md={0} lg={3} xxl={3}>
              <p className="xs" style={{ padding: '4px 0' }}>Síguenos en</p>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1em',
                background: '',
                marginBottom: '5px'
              }}>
                {socialItems.map(item => (
                  <div key={item.url} >
                    <a href={item.url} key={item.url} >
                      <IconSwitcher name={item.icon} style={{ fontSize: '1.5em', height: '100%' }} />
                    </a>
                  </div>
                ))}
              </div>
            </Col>
            <Col xs={0} md={0} lg={1} xxl={1}
            //onClick={() => setIsModalVisible(true)}
            >
              {/* <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                gap: '2pt'
              }}
              >
                <p className="">Simulador</p>
                <BsCalculator style={{ fontSize: 24 }} />
              </div> */}
            </Col>
            <Col xs={24} md={24} lg={10} xxl={10}>
              <Row>
                <Col xs={24} md={24} lg={24}>
                  {
                    data.map(item => (
                      <Link href={"/seguridad_y_datos/" + item.slug} key={item.slug}>
                        <span className="sm">
                          {`${item.titulo} | `}
                        </span>
                      </Link>
                    ))
                  }
                </Col>
                <Col xs={24} md={24}>
                  <p className="sm" style={{ padding: '5px 0 7px' }}>
                    GENESIS, Administradora de Fondos. ©Todos los derechos reservados.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>


        </BoxUi>
      </View>

      <ViewMini>
        <BoxUi noppadingX transparent>


          <Row justify="space-between" align="middle">
            <Col xs={18}>
              <Row gutter={[6, 6]} align="middle">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '3pt' }}>
                  <BiPhoneCall size={15} />
                  <span>Atención al Cliente <span className="strong900">0986044405</span></span>
                </div>
              </Row>
            </Col>
            <Col x={6}>
              <Row gutter={[6, 6]}>
                {socialItems.map(item => (
                  <Col key={item.url} >
                    <a href={item.url} key={item.url} >
                      <IconSwitcher name={item.icon} style={{ fontSize: '1em', height: '100%' }} />
                    </a>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </BoxUi>
      </ViewMini>


      {/* <CallToAction onClick={() => setIsModalVisible(true)}>
        <BsCalculator /> Simulador
      </CallToAction> */}



      {/* SEPARATION */}
      <Modal title="Elige tu Simulador" visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width={500}
        bodyStyle={{ padding: 20 }}
        footer={null}
      >
        <List
          itemLayout="horizontal"
          dataSource={[simulador, ...fondoOptions]}
          renderItem={(data) => (
            <SimuladorCard data={data} />
          )}
        />
      </Modal>
    </>
  )
}

export default Footer;

const View = styled.div`
    background:${THEME_COLORS.light.primary.alt + 'EF'};
    @media screen and  (min-width: ${MEDIA_SCREENS.SM}px)  { 
      position:sticky;
      bottom: 0;
    }

    border-top: 1pt solid ${THEME_COLORS.light.primary};
    color:${THEME_COLORS.light.primary.alt};
    height: 100%;
    z-index: 30; 
    color:white;
  .social_side{
        display: flex;
        justify-content:center;
        flex-direction:column;
        align-items:center;
        .icons{
          display:flex;
          gap:.9em;
          svg{
          font-size:1.8em;
          height:100%;
        }
      }
  }
  .phone_side_main {
      cursor:pointer;
      display:flex;
      gap: 0.3em;
      align-items:center;
      svg{
        font-size:2.1em;
      }
  }

  .text_footer {
      p, a, span{
      font-size: 10px;
        @media (min-width: 768px) {
          font-size: 14px;
        }
      }
  }

  .text-items {
      p, span {
        font-size: 12px;
        @media (min-width: 768px) {
          font-size: 14px;
        }
      }
  }


`

const ViewMini = styled.div`
   @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.MD}px) { 
      background:${THEME_COLORS.light.primary.alt + 'EF'};
      height: 100%;
      bottom: 0;
      position:sticky;
      z-index:30;
      color:white;
      svg{
        fill:white;
      }
    }
    @media screen and  (min-width: ${MEDIA_SCREENS.MD}px) { 
      display:none;
    }
`

const CallToAction = styled.div`
  position:fixed;
  bottom:2.1em;
  right:0;
  display:flex;
  align-items:center;
  gap:.6em;
  justify-content:flex-end;
  z-index:31;
  padding: 0.6em;
  border-radius:9pt 0 0 9pt;
  background:${THEME_COLORS.light.secondary.main};
  color:${THEME_COLORS.light.secondary.contrastText};
  ${CSS_HELPERS.TSCALE};
  @media screen and  (min-width: ${MEDIA_SCREENS.MD}px) { 
      display:none;
    }
`


