import { CSS_HELPERS } from "@theme/constants";
import styled from "styled-components";

const Switcher = ({ name }) => {
    const prefix = "/cuadros"
    switch (name) {
        case "perfil_tonos_claros": return <Img src={`${prefix}/perfil_tonos_claros.webp`} />
        case "rockets_illustration": return <Img src={`${prefix}/rockets_illustration.jpeg`} />

        default: return <Img src={`${prefix}/perfil_tonos_claros.webp`} />
    }
}



const CuadroSwitcher = ({ name }) => (
    <View>
        <Switcher name={name} />
    </View>
);


export default CuadroSwitcher;


const View = styled.div`
    height:100% ;
    width:100% ;
    background-color:black ;
`
const Img = styled.img`
    height:100% ;
    width:100% ;
    object-fit:cover ;
`

