import { THEME_COLORS } from '@theme/constants';
import Link from 'next/link';
import styled from 'styled-components';
const MenuCard = ({ slug, titulo, action }) => {
    // console.log({sluglog: slug});
    return (
        action ? (
            <View onClick={action}>
                {titulo}
            </View>
        ) :
            (
                <View>
                    <Link href={`/${slug}`}>
                        {titulo}
                    </Link>
                </View>
            )
    );
}

export default MenuCard;

const View = styled.li`
    cursor:pointer;
    display:flex ;
    align-items:center ;
    justify-content:center;
    height:100% ;
    width:100%;
    transition: background-color .2s ease;
    font-size:1em;
    :hover{
        background:${THEME_COLORS.light.primary.alt};
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;   
    }
    a {
        font-family: 'Noto Sans', sans-serif;
        color: white !important;
        transition: all .2s ease-in;
        text-transform: uppercase;
        /* font-weight: 500; */
        text-align:center;
        /* width: 145px; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 1em;
    }
    svg{
        color:white;
        font-size:1.2em ;
    }
`

