"use client";

import { CSS_HELPERS, THEME_COLORS } from '@theme/constants'
import { Typography } from 'antd'
import styled from 'styled-components'
import Bolder from '@components/common/Bolder';
import LineBreaker from './LineBreaker'

const TitleUi = ({
    titulo,
    carnada,
    descripcion_corta,

    variant,
    noMarginX,
    color,
    h2

}) => {
    if (!titulo) {
        return <></>
    }

    return (
        <View variant={variant} noMarginX={noMarginX} color={color}>
            <Typography.Title level={h2 ? 2 : 1} style={{ marginTop: 0 }}>
                <Bolder titulo={titulo} />
            </Typography.Title>

            {carnada && <p className="txt-carnada strong400">{carnada}</p>}
            {descripcion_corta &&
                <LineBreaker titulo={descripcion_corta} padding={'.3em 0'} />

            }
        </View>
    )
}

export default TitleUi;

const View = styled.div`

    .ant-typography {
        color:${THEME_COLORS.light.primary.alt};
        font-family: 'Georgia','Times New Roman','Times','serif';
        margin-bottom:0;
        ${props => props.color && `color:${props.color}`}};
    }
  

    .txt-carnada{
        color:${THEME_COLORS.light.primary.alt};
        margin-bottom:1.2em ;
    }

    ${props => props.variant === "color_box" && `
            ${CSS_HELPERS.BORDER_LINE_BOTTOM}
            padding:1em 3em;
            background-color: ${THEME_COLORS.light.primary.main};
            .ant-typography {
                color: ${THEME_COLORS.light.primary.contrastText};
            }
            border-radius:0 3.3em 0 0;
            .txt-carnada{
                color:${THEME_COLORS.light.primary.contrastText};
                margin:.3em 0;
            }

    `};

    display:flex ;
    flex-direction:column ;
    margin-bottom:2.4em ;
    ${props => props.noMarginX && `margin:0`}



`;


