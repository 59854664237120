import Link from 'next/link';
import React from 'react'

const EntradaLink = ({ data, preSlug }) => {

    const { titulo, slug } = data;

    return (
        <Link href={"/"+preSlug+"/"+slug} >
            <span className="xs">{titulo}</span>
        </Link>

    )
}

export default EntradaLink;