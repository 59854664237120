"use client";

import { THEME_COLORS } from "@theme/constants";
import Bolder from "./Bolder";

const LineBreaker = ({ titulo, bold, capitalize, size, padding, lineHeight }) => {

    let split = titulo && titulo.split("/n");

    // return <span>{split}</span>
    return (
        <>
            {split && split.length && split.map((item, index) => {
                const _bold = bold ? "strong600" : "strong400 "
                const _capitalize = capitalize ? 'cappi' : '';
                const _size = size ? size : 'md';
                const _lineHeight = lineHeight ? lineHeight : 'inherit'

                return (<p
                    key={index}
                    className={`${_bold} ${_capitalize} ${_size}`}
                    style={{ padding: padding, lineHeight: lineHeight }}>
                    <Bolder titulo={item} boldColor={THEME_COLORS.light.primary.alt}> </Bolder>
                </p>)
            })}
        </>
    )



}

export default LineBreaker