import IconSwitcher from "@components/common/IconSwitcher";
import { EficazIcon2 } from "@components/common/svgs";
import { THEME_COLORS } from "@theme/constants";
import { List } from "antd";
import Link from "next/link";
import styled from "styled-components";


export const SimuladorCard = ({ data }) => {
  const { titulo, slug, plazo } = data;

  return (
    <View>
      <Link href={`/simulador/${slug || 'horizonte'}`}>
        <List.Item>
          <List.Item.Meta
            className="list-content"
            //avatar={<IconSwitcher size={3} name={slug || 'horizonte'} />}
            avatar={slug == 'eficaz' ? <EficazIcon2 /> : <IconSwitcher footer={true} name={slug || 'horizonte'} />}
            title={<p style={{ marginLeft: 10 }}>{`Simulador ${titulo || 'Horizonte'}`}</p>}
            description={<p style={{ marginLeft: 10 }}>{`Permanencia de ${plazo ? `${plazo} días` : '36 meses'}`}</p>}
          />
        </List.Item>
      </Link>
    </View>
  )
};

const View = styled.div`
  padding: 0 1rem;
  cursor: pointer;
  border-radius: 1rem;
  transition: all 0.2s ease;
  :hover {
    background-color: ${THEME_COLORS.light.bg.alt};
  }

  .list-content {
    display: grid;
    grid-template-columns: 20% 1fr;
    align-items: center;
    justify-content: center;

    .ant-list-item-meta-content {
      width: 100%;
    }
  }
`
