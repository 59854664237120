import { MEDIA_SCREENS, THEME_COLORS } from '@theme/constants';
import { Button, Col, Row } from 'antd';
import styled from 'styled-components';
import IconSwitcher from '@components/common/IconSwitcher';
import Link from 'next/link';
import BoxUi from '@components/common/BoxUi';
import Buscar from '../Buscar';
import { BiHome, BiMenu } from 'react-icons/bi';

const AppBar = ({ handleDrawer }) => {
    return (
        <>
            <View>
                <BoxUi noppadingX>
                    {/* BARRA DE MENU DESKTOP */}
                    <Row align="middle" justify="space-between" className="appbar_large">
                        <Col xs={0} sm={5} className="brand_logo" >
                            <Link href="/">
                                <IconSwitcher name="logo" />
                            </Link>
                        </Col>
                        <Col xs={0} sm={3}>
                            <Button color='red' icon={<BiHome size={18} />} block type="link" size="large">
                                <Link href="/" style={{ fontWeight: 'bold' }}>
                                    Inicio
                                </Link>
                            </Button>
                        </Col>
                        <Col xs={0} sm={3}>
                            <Button icon={<BiMenu size={18} />} block type="link" size="large" onClick={handleDrawer}>Menu</Button>
                        </Col>
                        <Col xs={0} sm={9}>
                            {/* <Buscar /> */}
                        </Col>
                        <Col xs={0} sm={4}>
                            <Button className='btn_custom_secondary' type="primary" style={{ width: '100%', fontWeight: '600' }}>
                                <Link
                                    // href="https://digital.fondosgenesis.com/gen_transac" 
                                    href="/maintenance"
                                    rel="noopener noreferrer"
                                //target={'_blank'}
                                >
                                    GENESIS VIRTUAL
                                </Link>
                            </Button>
                        </Col>
                    </Row>

                    {/* BARRA DE MENU MOBIL */}
                    <Row align="middle" className="appbar_xs">
                        <Col xs={10} className="brand_logo" >
                            <Link href="/">

                                <IconSwitcher name="logo" />
                            </Link>
                        </Col>
                        <Col xs={2} >
                            <Link href="/">
                                <Button icon={<BiHome size={18} />} block type="link" onClick={handleDrawer} />
                            </Link>
                        </Col>

                        <Col xs={2} >
                            <Buscar mini />
                        </Col>
                        <Col xs={8} align="middle">
                            <Link
                                // href="https://digital.fondosgenesis.com/gen_transac" 
                                href="/maintenance"
                                rel="noopener noreferrer"
                            //target={'_blank'}
                            >
                                GENESIS VIRTUAL
                            </Link>
                        </Col>
                        <Col xs={2} >
                            <Button icon={<BiMenu size={18} />} block type="link" onClick={handleDrawer} />
                        </Col>
                    </Row>
                </BoxUi>
            </View>
        </>

    )
}


{/* <ModalView /> */ }

export default AppBar;

const View = styled.div`
    a{
        font-weight:600;
    }
    a,svg,span {
        color:${THEME_COLORS.light.primary.alt};
    }
    .btn_custom_secondary,
    .btn_custom_secondary a{ 
        color:white;
    }
    

.ant-btn-icon {
    margin-inline-end: 0 !important;
}

.appbar_large{
    @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.SM}px) { 
        display:none;
    }
    .brand_logo{
        display:flex;
        svg{
            height:3em;
            width:100%;
        }
    }
    .ant-col{
        padding:.6em;
    }
}

.appbar_xs{
    @media screen and  (min-width: ${MEDIA_SCREENS.SM}px)  { 
        display:none;
    }
    .brand_logo{
        display:flex;
        svg{
            /* height:3em; */
            width:100%;
            padding-right:.9em;

        }
     
    }
    .ant-col{
        padding:.3em;
    }

}

`



