import { THEME_COLORS } from '@theme/constants';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Popover, Row, Col, Button } from 'antd';
import _ from 'lodash';

import styled from 'styled-components';
import BuscarItem from './BuscarItem';
import { BiSearch } from 'react-icons/bi';


const Buscar = ({ mini }) => {
    const { entradas } = useSelector(state => state.entradas);
    const [searchValue, setSearchValue] = useState("");

    const _entradas = _.orderBy(entradas, ['vista', 'orden'])

    //const filtered = searchValue.length ? _entradas.filter(e => e.titulo.toUpperCase().includes(searchValue.toUpperCase())) : _entradas;
    const filtered = searchValue.length ? _entradas.filter(e => e.titulo.toUpperCase().includes(searchValue.toUpperCase())) : _entradas.filter(e => Boolean(e.slug) && e.titulo.trim().length > 0);
    //console.log({ _entradas });

    return (
        <Popover
            overlayStyle={{ borderRadius: '0 0 12pt 12pt', overflow: 'hidden' }}
            placement="bottom"
            content={
                <SearchList focused={true} >
                    {mini &&
                        <Input
                            onChange={e => setSearchValue(e.target.value)}
                            placeholder="BUSCAR EN GENESIS"
                            prefix={<></>}
                            size="small"
                            style={{ width: '100%' }}
                        />
                    }
                    {filtered.map(item => <BuscarItem {...item} key={item.id} />)}
                </SearchList>
            }>
            <Row align='middle' justify="center" >
                <Col style={{ display: 'flex', justifyContent: 'center' }} xs={9}>
                    <Button type="link" icon={<BiSearch />} size="large" style={{ padding: 0 }} >
                        {!mini && "Buscador"}
                    </Button>
                </Col>

                {!mini &&
                    <Col xs={15}>
                        <Row align='middle'>
                            <BuscarView>
                                <Input
                                    onChange={e => setSearchValue(e.target.value)}
                                    placeholder="BUSCAR EN GENESIS"
                                    prefix={<></>}
                                    size="small"
                                    style={{ width: '100%' }}
                                />
                            </BuscarView>

                        </Row>

                    </Col>
                }
            </Row>
        </Popover>
    )
}

export default Buscar;

const BuscarView = styled.div`
    width:100% ;
    .ant-input-affix-wrapper{
        .ant-input{
            height:2em
        }
    }
`
const SearchList = styled.div`
    background-color: ${THEME_COLORS.light.bg.main};
    height: 40em;
    overflow-y:scroll;
    padding: 1em 0;
    display:flex ;
    flex-direction: column;
    gap: 1em;
`;

