import { useSelector } from '@redux/store';
import { MEDIA_SCREENS, THEME_COLORS } from '@theme/constants'
import _ from "lodash";
import MenuCard from './MenuCard';
import styled from 'styled-components';
import { BiDotsVertical } from 'react-icons/bi';
import BoxUi from '@components/common/BoxUi';
import { IoMenu } from 'react-icons/io5';


const MenuBar = (
    { handleDrawer },
) => {
    const { entradas } = useSelector(state => state.entradas)
    let secciones = _.orderBy(entradas.filter(item => (item.vista == "categorías")), 'orden', 'asc');

    secciones = secciones.splice(0, 8).filter(item => item.slug != 'empleadores');
    //console.log(secciones);

    return (

        <View>
            <BoxUi transparent noppadingX>

                <MenuList>
                    <ul>
                        {secciones.map(item => <MenuCard {...item} key={item.id}></MenuCard>)}
                        <MenuCard titulo={<IoMenu />} action={handleDrawer}></MenuCard>
                    </ul>
                </MenuList>
            </BoxUi>

        </View>


    )
}

export default MenuBar;

const View = styled.div`
    background:${THEME_COLORS.light.primary.alt + 'EF'};

    @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.SM}px) { 
        display:none
    };
`

const MenuList = styled.nav`
 ul{
    display:flex;
    justify-content:space-between;
    height: 2.8em;
    padding:0;
    margin:0;
 }
`
