
import EntradaLink from './EntradaLink';
import BoxUi from '@components/common/BoxUi';
import Link from 'next/link';
import { MEDIA_SCREENS } from '@theme/constants';
import styled from 'styled-components';
import _ from 'lodash';

export const SiteMap = ({ data }) => {


    const parsedData =
        data
            .filter(item => (
                item.slug == "noticias" ||
                item.slug == "gente_genesis" ||
                item.slug == "inversion" ||
                item.slug == "empleadores"
            ))
            .map(item => ({
                ...item,
                entradas: _.orderBy(item.entradas.filter(item => item.acceso_directo), 'created_at', 'desc')
            }));


    return (
        <BoxUi background={true}>
            {/* <TitleUi titulo="Secciones que te pueden interesar"/> */}
            <View >
                {
                    parsedData.map(({ id, titulo, slug, entradas }) => (
                        <Item key={id} xs={24} sm={8}>
                            <Link href={slug}>
                                <span className="md">{titulo}</span>
                            </Link>
                            <ul>
                                {
                                    entradas.map((item) => (
                                        <li key={item.id}>

                                            <EntradaLink data={item} preSlug={slug} />
                                        </li>
                                    ))
                                }
                            </ul>
                        </Item>
                    ))
                }
            </View>
        </BoxUi>
    )
};

export default SiteMap;




const View = styled.div`
    display:flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    @media (min-width: ${MEDIA_SCREENS.SM}px) {
        flex-direction: row;
    }
`;

const Item = styled.div`  
    padding:0;
    display:flex;
    flex-direction:column;
   
`
