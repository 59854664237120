import IconSwitcher from '@components/common/IconSwitcher';
import { CSS_HELPERS, THEME_COLORS } from '@theme/constants';
import { Typography } from 'antd';
import Link from 'next/link';
import React from 'react'
import styled from 'styled-components';

const DrawerCard = ({
  id,
  acceso_directo,
  orden,
  slug,
  titulo,
  carnada,
  descripcion_corta,
  descripcion_larga,
  vista,
  fotoUrl,
  createdAt,
  updatedAt,
  deletedAt,
  figuraId,
  entrada,
  caracteristicas,
  acciones,
  detalles,
  entradas,
  grid
}) => {
  return (

    <CardContainer grid={grid}>
      <Link href={`/${slug}`}>
        <IconSwitcher name={slug} size={1.2} />
        <p className='sm'>
          {titulo}
        </p>
      </Link>
    </CardContainer>


  )
}

export default DrawerCard;



const CardContainer = styled.li`
    ${CSS_HELPERS.BOX_SHADOW_BOTTOM};
    a{
      cursor:pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:1em;
    padding: 1em;
    line-height: 25px;
    }
    svg{
        /* height:100% ; */
        display:flex;
        justify-content:center;
        fill:${THEME_COLORS.light.primary.contrastText};
    };
    p{
      font-weight: 500;
      font-size: 15px;
      text-align: center;
      text-transform: UpperCase;
      color:${THEME_COLORS.light.primary.contrastText};
    };

    :hover{
      background-color: ${THEME_COLORS.light.primary.main};
      transition: all .2s ease-in;
      svg{
        fill:${THEME_COLORS.light.primary.contrastText};
      };
      p{
        color:${THEME_COLORS.light.primary.contrastText};
      };
    } 

    ${props => props.grid && `
      p{
        font-weight: 900;
        text-align: center;
        color:${THEME_COLORS.light.text.dark};
        text-transform: uppercase;
      };
      background-color: ${THEME_COLORS.light.primary.contrastText};

      ${CSS_HELPERS.BOX_SHADOW};

    `}



`
