"use client";

import Head from 'next/head';
import styled from 'styled-components';

import { useDispatch, useSelector } from '@redux/store';
import { useEffect } from 'react';
import { useState } from 'react';
import MenuBar from './Menu/MenuBar';
import Footer from './Footer';
import { Breadcrumb } from 'antd';

import BannerProvida from './Banners/BannerProvida';
import BannerInvierteAqui from './Banners/BannerInvierteAqui'
import LoginForm from './Usuario/LoginForm';
import AppBar from './Menu/AppBar/Index';
import { usePathname } from 'next/navigation';
import { findEntradas } from '@redux/slices/entradas';
import { MEDIA_SCREENS, THEME_COLORS } from '@theme/constants';
import SiteMap from '@components/home/Sitemap';
import _ from 'lodash';
import MenuDrawer from './Menu/MenuDrawer';
import BoxUi from '@components/common/BoxUi';
import Link from 'next/link';



const CmsLayout = ({ children, title = '', meta = "" }) => {
    const [toggle, setToggle] = useState(false);
    const handleDrawer = () => setToggle(!toggle);
    const pathname = usePathname();

    const isInManagerPath = pathname?.includes('manager');
    // GRAB ENTRADAS
    const { entradas, seguridadYDatos } = useSelector(state => state.entradas)
    const secciones = _.orderBy(entradas?.filter(item => (item.vista == "categorías")), 'orden', 'asc');

    //DISPATCH SESSION
    const dispatch = useDispatch();
    const { usuario, rutasCms } = useSelector(item => item.sesion);
    const [currentPath, setcurrentPath] = useState('')

    useEffect(() => {
        if (!entradas?.length) dispatch(findEntradas());
    }, [dispatch, entradas.length]);


    useEffect(() => {
        setcurrentPath(pathname?.split('/manager/')[1])
        // const ruta = rutasCms.find(item => item.slug === currentPath);
        // ruta && !ruta?.show && router.push('/manager');
    }, [currentPath, rutasCms, pathname])


    //RETURN IF NO SESSION
    if (isInManagerPath && !usuario.id) {
        return (<LoginForm />)
    }

    // manager layout
    if (isInManagerPath && usuario.id) {
        return (
            <View>
                <Head>
                    <title>{title}  GENESIS Administradora de fondos</title>
                    <meta name="googlebot" content="index, follow" />
                    <meta name="robots" content="index, follow" />
                    <meta name="description" content={meta} />
                </Head>
                <Top>
                    <AppBar toggle={toggle} handleDrawer={handleDrawer} />
                    <MenuDrawer toggle={toggle} handleDrawer={handleDrawer} />
                </Top>

                <BoxUi>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link href="/manager">
                                Manager
                            </Link>

                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link href={"/manager/" + currentPath}>
                                {currentPath}
                            </Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </BoxUi>

                <Content>
                    {children}
                </Content>

                <SiteMap data={secciones} />
                <Footer data={seguridadYDatos} />
            </View>

        )
    }

    //RETURN IF SESSION
    return (
        <View>
            <Head>
                <title>{title}  GENESIS Administradora de fondos</title>
                <meta name="description" content={meta} />
            </Head>
            <Top>
                <AppBar toggle={toggle} handleDrawer={handleDrawer} />
                <MenuBar toggle={toggle} handleDrawer={handleDrawer} />
                <MenuDrawer toggle={toggle} handleDrawer={handleDrawer} />
            </Top>
            <Content>
                {children}
            </Content>
            {/* <BannerInvierteAqui /> */}
            <BannerProvida />
            {/* <SiteMap data={secciones} /> */}
            <Footer data={seguridadYDatos} />
        </View>

    );
}

export default CmsLayout;


const View = styled.div``

const Top = styled.div`
    position:sticky;
    top: 0;
    z-index:16;
    width:100%;
    border-bottom:2px solid ${THEME_COLORS.light.bg.alt};
`;

const Content = styled.main`
    // position:absolute;
    width:100%;
    overflow:hidden;
    // @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.XS}px) { 
    //     margin-top: ${3.6 + 4.8}em;
    // };
    // @media screen and  (min-width: ${MEDIA_SCREENS.XS}px) and (max-width: ${MEDIA_SCREENS.SM}px) { 
    //     margin-top: ${3.6 + 4.8}em;
    // };
    // @media screen and  (min-width: ${MEDIA_SCREENS.SM}px) and (max-width: ${MEDIA_SCREENS.MD}px) { 
    //     margin-top: ${3.6 + 4.8 + 3.3}em;
    // };
    // @media screen and  (min-width: ${MEDIA_SCREENS.MD}px) and (max-width: ${MEDIA_SCREENS.LG}px) { 
    //     margin-top: ${3.6 + 4.8}em;
    // };
    // @media screen and  (min-width: ${MEDIA_SCREENS.LG}px) { 
    //     margin-top: ${3.6 + 4.8}em;
    // };
`

