import IconSwitcher from '@components/common/IconSwitcher';
import { useSelector } from '@redux/store';
import { THEME_COLORS } from '@theme/constants';
import { Drawer } from 'antd'
import _ from 'lodash';
import { BiX } from 'react-icons/bi';
import styled from 'styled-components';
import DrawerCard from './DrawerCard'

const MenuDrawer = ({
    handleDrawer,
    toggle
}) => {

    const { entradas } = useSelector(state => state.entradas)
    const secciones = _.orderBy(entradas.filter(item => (item.vista == "categorías" && item.slug != 'descargas')), 'orden', 'asc');

    return (
        <Drawer
            placement="left"
            onClose={handleDrawer}
            visible={toggle}
            headerStyle={{ display: 'none' }}
            maskStyle={{ padding: 0, background: "#FFFFFF00" }}
            bodyStyle={{ padding: 0, background: THEME_COLORS.light.primary.alt + 'E8' }}
            className="custom_drawer"
        >
            <View>
                <DrawerHeader>
                    <div className="logo_icon" >
                        <IconSwitcher name="contrast_logo" />
                    </div>
                    <BiX className="close_icon" onClick={handleDrawer} />
                </DrawerHeader>
                <Grid onClick={() => handleDrawer(!toggle)}>
                    {secciones.map(item => (<DrawerCard {...item} key={item.id} />))}
                </Grid>
            </View>

        </Drawer>

    )
}

export default MenuDrawer;

const View = styled.nav`
    height:100vh;
    background:#00000010;
`

const Grid = styled.ul`
 padding:0;
    margin:0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
`;

const DrawerHeader = styled.div`
    display:flex;
    align-items:center;
    display:flex;
    justify-content:space-between;
    padding: 1em;
    .logo_icon{
        font-size:1.1em 
    }
    .close_icon{
        color:white;
        font-size:2em;
        cursor:pointer;
    }
`
